import React, { useEffect, useState } from 'react'

import LoggedOutLayout from '../components/layouts/LoggedOutLayout'
import ReportModal from '../components/cases/ReportModal'
import '../components/index.scss'
import { getWindow, searchQueryParams } from '../lib/helpers'

export default function Consultation() {
  const search = getWindow(['location', 'search'])

  const [presignedUrl, setPresignedUrl] = useState<string | undefined>()
  const [message, setMessage] = useState<string | undefined>()
  const [sender, setSender] = useState<string | undefined>()

  useEffect(() => {
    if (!search) return

    setPresignedUrl(search.split('url=')[1])
    setMessage(decodeURIComponent(searchQueryParams('message') || ''))
    setSender(decodeURIComponent(searchQueryParams('sender') || ''))
  }, [search])

  return (
    <LoggedOutLayout>
      {presignedUrl && <ReportModal isShared={true} sender={sender} senderMessage={message} url={presignedUrl} />}
    </LoggedOutLayout>
  )
}
